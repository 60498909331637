<template>
  <div class="footerContainer">
    <div class="footer w">
      <div :class="$i18n.locale == 'en' ? 'en_left' : 'zh_left'">
        <div class="item">
          <div class="title">{{ $t('产品系列') }}</div>
          <div class="cursor" @click="$router.push('/cloudPlatform')">
            {{ $t('OSAI云平台') }}
          </div>
          <div class="cursor" @click="algorithmJump('AIAlgorithm')">
            {{ $t('AI生鲜识别算法') }}
          </div>
          <div class="cursor" @click="algorithmJump('AISnack')">
            {{ $t('AI零食识别算法') }}
          </div>
          <div class="cursor" @click="algorithmJump('AIBaking')">
            {{ $t('AI烘焙识别算法') }}
          </div>
          <div class="cursor" @click="$router.push('/tensorPro')">
            Tensor Pro
          </div>
          <div class="cursor" @click="$router.push('/tensorMini')">
            Tensor Mini
          </div>
        </div>
        <div class="item">
          <div class="title">{{ $t('解决方案') }}</div>
          <div class="cursor" @click="$router.push('/industrySolution')">
            {{ $t('行业解决方案') }}
          </div>
          <div class="cursor" @click="$router.push('/aiCashierSolutions')">
            {{ $t('收银解决方案') }}
          </div>
          <div class="cursor" @click="$router.push('/aIBarcodeScaleSolution')">
            {{ $t('条码秤解决方案') }}
          </div>
          <div class="cursor" @click="$router.push('/rulerSolution')">
            {{ $t('原木精确智能检尺解决方案') }}
          </div>
          <div
            class="cursor"
            @click="$router.push('/photovoltaicLayingSolution')"
          >
            {{ $t('光伏铺装解决方案') }}
          </div>
          <div
            class="cursor"
            @click="$router.push('/photovoltaicCleaningSolutions')"
          >
            {{ $t('光伏清洁解决方案') }}
          </div>
        </div>
        <div class="item">
          <div class="title">{{ $t('联系我们') }}</div>
          <div>{{ $t('咨询电话：13346402280') }}</div>
          <div>{{ $t('联系邮箱：info@osai.cc') }}</div>
        </div>
      </div>
      <div class="right" v-show="$i18n.locale == 'zh'">
        <div class="item">
          <img src="../../assets/二维码图标/公众号.png" alt="" />
          <div class="text">微信公众号</div>
        </div>
        <div class="item">
          <img src="../../assets/二维码图标/视频号.png" alt="" />
          <div class="text">企业视频号</div>
        </div>
      </div>
      <div class="en_right" v-show="$i18n.locale == 'en'">
        <a href="https://www.youtube.com/@OSAILab" target="_blank">
          <div class="item">
            <img src="../../assets/footer/video.png" alt="" />
            <!-- <div class="text">微信公众号</div> -->
          </div>
        </a>
        <div class="item">
          <img src="../../assets/footer/f.png" alt="" />
          <!-- <div class="text">企业视频号</div> -->
        </div>
      </div>
    </div>

    <div class="gonganInfo w">
      <div>© 2024 珠海傲视创新科技有限公司 OSAI Inc. All Rights Reserved.</div>
      <div>
        <a href="https://beian.miit.gov.cn/" target="_blank"
          >粤ICP备2021150212号-1</a
        >
      </div>
      <div class="gongan">
        <img src="../../assets/img/goods/gongan.png" alt="" />
        <div>
          <a href="https://www.beian.gov.cn/portal/index.do" target="_blank"
            >粤公网安备44049102496925号</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    //算法的锚点跳转
    algorithmJump(val) {
      this.$router.push({
        path: '/aiCoreAlgorithms',
        query: { t: Date.now(), anchor: val }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.footerContainer {
  background: #344f98;
  height: 440px;
  .footer {
    display: flex;
    padding: 30px 100px 0px 100px;
    justify-content: space-between;
    .cursor {
      cursor: pointer;
    }
    .zh_left {
      height: fit-content;
      width: 720px;
      justify-content: space-between;
      display: flex;
      .item {
        .title {
          font-weight: 500;
          font-size: 24px;
          color: #fff;
          margin-bottom: 9px;
          line-height: 34.75px;
        }
        div {
          margin-bottom: 11.31px;
          line-height: 26.06px;
          font-size: 18px;
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
    .en_left {
      height: fit-content;
      width: 917px;
      justify-content: space-between;
      display: flex;
      .item {
        .title {
          font-weight: 500;
          font-size: 24px;
          color: #fff;
          margin-bottom: 9px;
          line-height: 34.75px;
        }
        div {
          margin-bottom: 11px;
          line-height: 26.06px;
          font-size: 18px;
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
    .right {
      display: flex;
      color: #fff;
      .item {
        margin-right: 12px;
        width: 131px;
        height: 128px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .text {
        margin-top: 8px;
        line-height: 28.96px;
        font-weight: 700;
        font-size: 20px;
        text-align: center;
      }
    }
    .en_right {
      height: fit-content;
      display: flex;
      color: #fff;
      .item {
        display: flex;
        margin-left: 25px;
        width: 70px;
        height: 70px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .text {
        margin-top: 8px;
        line-height: 28.96px;
        font-weight: 700;
        font-size: 20px;
        text-align: center;
      }
    }
  }
  .gonganInfo {
    margin-top: 70px;
    color: rgba(255, 255, 255, 0.5);
    padding-left: 100px;
    display: flex;
    font-size: 18px;
    div {
      margin-right: 20px;
    }
    .gongan {
      display: flex;
      align-items: center;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
}
</style>
