import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/', component: () => import('../view/pc/home/index.vue') },
  { path: '/aboutUs', component: () => import('../view/pc/aboutUs/index.vue') },
  {
    path: '/aIBarcodeScaleSolution',
    component: () => import('../view/pc/aIBarcodeScaleSolution/index.vue')
  },
  {
    path: '/industrySolution',
    component: () => import('../view/pc/industrySolution/index.vue')
  },
  {
    path: '/aiCoreAlgorithms',
    component: () => import('../view/pc/aiCoreAlgorithms.vue')
  },
  {
    path: '/photovoltaicLayingSolution',
    component: () => import('../view/pc/photovoltaicLayingSolution/index.vue')
  },
  {
    path: '/photovoltaicCleaningSolutions',
    component: () =>
      import('../view/pc/photovoltaicCleaningSolutions/index.vue')
  },
  {
    path: '/rulerSolution',
    component: () => import('../view/pc/rulerSolution/index.vue')
  },
  {
    path: '/tensorMini',
    component: () => import('../view/pc/tensorMini/index.vue')
  },
  {
    path: '/tensorPro',
    component: () => import('../view/pc/tensorPro/index.vue')
  },
  {
    path: '/aIBarcodeScaleSolution',
    component: () => import('../view/pc/aIBarcodeScaleSolution/index.vue')
  },
  {
    path: '/aiCashierSolutions',
    component: () => import('../view/pc/aiCashierSolutions/index.vue')
  },
  {
    path: '/cloudPlatform',
    component: () => import('../view/pc/cloudPlatform/index.vue')
  },
  { path: '/phone', component: () => import('../view/phone/index.vue') }
]

const router = new VueRouter({
  routes // (缩写) 相当于 routes: routes
})

router.beforeEach((to, from, next) => {
  next()
  window.scrollTo(0, 0)
})

export default router
