<template>
  <!-- 头部 -->
  <div class="headerContainer">
    <div class="header w">
      <div class="left">
        <h1 class="logo">
          <h1>珠海傲视创新科技有限公司</h1>
          <img src="@/assets/img/logo.png" alt="" />
        </h1>
        <div class="nav">
          <div class="item" @click="handleTo('/')">
            <div class="title cursor">{{ $t('header.首页') }}</div>
          </div>
          <div
            class="item"
            @mouseenter="handleMouseEnter('productLineShow')"
            @mouseleave="handleMouseLeave('productLineShow')"
          >
            <div class="title cursor" :class="{ titleTwo: productLineShow }">
              {{ $t('产品系列') }}
            </div>
            <transition name="el-zoom-in-top">
              <div class="drop_down_container" v-show="productLineShow">
                <div class="drop_down_box w">
                  <div class="drop_item_left">
                    <div>
                      <div class="title">{{ $t('云平台服务') }}</div>
                      <div class="info">
                        {{ $t('高效的数据分析和设备管理。') }}
                      </div>
                    </div>
                    <div>
                      <div class="img_item" @click="handleTo('/cloudPlatform')">
                        <img
                          src="../../assets/下拉栏/产品系列/云平台.png"
                          alt=""
                        />
                        <div class="text">{{ $t('OSAI云平台') }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="drop_item_center">
                    <div class="cursor">
                      <div class="title" @click="handleTo('/aiCoreAlgorithms')">
                        {{ $t('AI智能识别算法') }}
                      </div>
                      <div class="info">
                        {{
                          $t(
                            '商业设备智能化，提升工作效率解决消费者排队等待问题，有效节省商户开支。'
                          )
                        }}
                      </div>
                    </div>
                    <div class="img_box">
                      <div
                        class="img_item"
                        @click="algorithmJump('AIAlgorithm')"
                      >
                        <img
                          src="../../assets/下拉栏/产品系列/AI生鲜识别算法.png"
                          alt=""
                        />
                        <div
                          :class="$i18n.locale == 'en' ? 'en_text' : 'zh_text'"
                        >
                          {{ $t('header.AI生鲜识别算法') }}
                        </div>
                      </div>
                      <div class="img_item" @click="algorithmJump('AISnack')">
                        <img
                          src="../../assets/下拉栏/产品系列/AI零食识别算法.png"
                          alt=""
                        />
                        <div
                          :class="$i18n.locale == 'en' ? 'en_text' : 'zh_text'"
                        >
                          {{ $t('header.AI零食识别算法') }}
                        </div>
                      </div>
                      <div class="img_item" @click="algorithmJump('AIBaking')">
                        <img
                          src="../../assets/下拉栏/产品系列/AI烘焙识别算法.png"
                          alt=""
                        />
                        <div
                          :class="$i18n.locale == 'en' ? 'en_text' : 'zh_text'"
                        >
                          {{ $t('header.AI烘焙识别算法') }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="drop_item_right">
                    <div>
                      <div class="title">{{ $t('3D双目摄像头') }}</div>
                      <div class="info">
                        {{ $t('室内外全场景的高精度双目立体3D相机。') }}
                      </div>
                    </div>
                    <div class="img_box">
                      <div class="img_item" @click="handleTo('/tensorPro')">
                        <img
                          src="../../assets/下拉栏/产品系列/Tensor Pro.png"
                          alt=""
                        />
                        <div
                          :class="$i18n.locale == 'en' ? 'en_text' : 'zh_text'"
                        >
                          Tensor Pro
                        </div>
                      </div>
                      <div class="img_item" @click="handleTo('/tensorMini')">
                        <img
                          src="../../assets/下拉栏/产品系列/Tensor Mini.png"
                          alt=""
                        />
                        <div
                          :class="$i18n.locale == 'en' ? 'en_text' : 'zh_text'"
                        >
                          Tensor Mini
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
          <div
            class="item"
            @mouseenter="handleMouseEnter('solutionShow')"
            @mouseleave="handleMouseLeave('solutionShow')"
          >
            <div class="title cursor" :class="{ titleTwo: solutionShow }">
              {{ $t('解决方案') }}
            </div>
            <transition name="el-zoom-in-top">
              <div class="drop_down_container">
                <div class="drop_down_box_solutions w" v-show="solutionShow">
                  <div class="solution_drop_item_left">
                    <div
                      class="cursor"
                      @click="$router.push('/industrySolution')"
                    >
                      <div class="title">{{ $t('行业解决方案') }}</div>
                      <div class="info">
                        {{ $t('基于AI核心算法，深入行业应用，解决用户痛点。') }}
                      </div>
                    </div>
                    <div class="img_box">
                      <div
                        class="img_item"
                        @click="handleTo('/aiCashierSolutions')"
                      >
                        <img
                          src="../../assets/下拉栏/解决方案/AI收银解决方案.png"
                          alt=""
                        />
                        <div
                          :class="$i18n.locale == 'en' ? 'en_text' : 'zh_text'"
                        >
                          {{ $t('AI收银解决方案') }}
                        </div>
                      </div>
                      <div
                        class="img_item"
                        @click="handleTo('/aIBarcodeScaleSolution')"
                      >
                        <img
                          src="../../assets/下拉栏/解决方案/AI条码秤解决方案.png"
                          alt=""
                        />
                        <div
                          :class="$i18n.locale == 'en' ? 'en_text' : 'zh_text'"
                        >
                          {{ $t('AI条码秤解决方案') }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="solution_drop_item_right"
                    :class="
                      $i18n.locale == 'en'
                        ? 'en_solution_drop_item_right_margin_bottom'
                        : 'zh_solution_drop_item_right_margin_bottom'
                    "
                  >
                    <div>
                      <div class="title">{{ $t('3D双目摄像头解决方案') }}</div>
                      <div class="info">
                        {{
                          $t(
                            '搭载自研双目立体视觉核心算法，在1.5到30米或0.3到15米距离内可以达到毫米级深度误差。'
                          )
                        }}
                      </div>
                    </div>
                    <div class="img_box">
                      <div class="img_item" @click="handleTo('/rulerSolution')">
                        <img
                          src="../../assets/下拉栏/解决方案/原木精确智能检尺解决方案.png"
                          alt=""
                        />
                        <div
                          :class="
                            $i18n.locale == 'en' ? 'en_3D_text' : 'zh_text'
                          "
                        >
                          {{ $t('原木精确智能检尺解决方案') }}
                        </div>
                      </div>
                      <div
                        class="img_item"
                        @click="handleTo('/photovoltaicLayingSolution')"
                      >
                        <img
                          src="../../assets/下拉栏/解决方案/光伏铺装解决方案.png"
                          alt=""
                        />
                        <div
                          :class="
                            $i18n.locale == 'en' ? 'en_3D_text' : 'zh_text'
                          "
                        >
                          {{ $t('光伏铺装解决方案') }}
                        </div>
                      </div>
                      <div
                        class="img_item"
                        @click="handleTo('/photovoltaicCleaningSolutions')"
                      >
                        <img
                          src="../../assets/下拉栏/解决方案/光伏清洁解决方案.png"
                          alt=""
                        />
                        <div
                          :class="
                            $i18n.locale == 'en' ? 'en_3D_text' : 'zh_text'
                          "
                        >
                          {{ $t('光伏清洁解决方案') }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
          <div class="item" @click="handleTo('/aboutUs')">
            <div class="title cursor">{{ $t('关于我们') }}</div>
          </div>
          <!-- <div>中文</div> -->
          <a
            :href="
              $i18n.locale == 'en'
                ? 'https://docs.osai.cc/web/en/'
                : 'https://docs.osai.cc/web/'
            "
            target="_blank"
          >
            <div class="item">
              <span class="title">{{ $t('文档中心') }}</span>
            </div>
          </a>
          <!-- <div class="item">
              <a href="https://ucp.osai.cc" target="_blank">云平台登录</a>
            </div> -->
          <!-- <el-dropdown @command="languageFn">
            <span>{{ lang == 'en' ? 'English' : '中文' }}</span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="zh">中文</el-dropdown-item>
              <el-dropdown-item command="en">English</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
        </div>
      </div>
      <div class="right">
        <div class="language">
          <div class="ico">
            <img src="../../assets/ico/路径 1(1).png" alt="" />
          </div>
          <!-- <div>简体中文</div> -->
          <el-dropdown @command="languageFn">
            <span class="lang_text">{{
              lang == 'en' ? 'English' : '简体中文'
            }}</span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="zh">简体中文</el-dropdown-item>
              <el-dropdown-item command="en">English</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <a href="https://ucp.osai.cc/#/loginIndex/login" target="_blank">
          <div class="loginBth">{{ $t('登录云平台') }}</div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lang: '' || this.$i18n.locale, //语言标识
      productLineShow: false, //产品系列下拉框是否显示,
      solutionShow: false //解决方案下拉框是否显示
    }
  },
  mounted() {
    // localStorage.setItem('headerHeight', this.$refs.header.offsetHeight)
  },
  watch: {
    '$i18n.locale'(val) {
      this.languageFn(val)
    }
  },
  methods: {
    //选择语言时触发
    languageFn(val) {
      this.lang = val
      this.$i18n.locale = val // 设置给本地的i18n插件
      localStorage.setItem('lang', val) //设置语言
    },
    handleTo(val) {
      localStorage.setItem('toId', '')
      this.$router.push({ path: val, query: { t: Date.now() } })
      this.productLineShow = false //产品系列下拉框是否显示,
      this.solutionShow = false //解决方案下拉框是否显示
    },
    //算法的锚点跳转
    algorithmJump(val) {
      this.$router.push({
        path: '/aiCoreAlgorithms',
        query: { t: Date.now(), anchor: val }
      })
      this.productLineShow = false //产品系列下拉框是否显示,
      this.solutionShow = false //解决方案下拉框是否显示
    },
    //鼠标移入
    handleMouseEnter(val) {
      if (val === 'productLineShow') {
        this.productLineShow = true
      } else {
        this.solutionShow = true
      }
    },
    //鼠标移出去
    handleMouseLeave(val) {
      if (val === 'productLineShow') {
        this.productLineShow = false
      } else {
        this.solutionShow = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.headerContainer {
  position: fixed;
  top: 0px;
  z-index: 999;
  width: 100%;
  height: 80px;
  background: url(../../assets/首页/首页底图.png) no-repeat center;
  background-size: cover;
  .header {
    padding: 0px 40px;
    .cursor {
      cursor: pointer;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    padding: 0px 40px;
    .left {
      height: 100%;
      display: flex;
      align-items: center;
      .logo {
        position: relative;
        h1 {
          position: absolute;
          left: -9999px;
        }
        overflow: hidden;
        margin-right: 35px;
        width: 101.79px;
        height: 50px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .nav {
        height: 100%;
        display: flex;
        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          // padding: 30px;
          height: 100%;
          .title {
            line-height: 80px;
            text-align: center;
            // width: 132px;
            padding: 0px 26px;
          }
          &:hover .title {
            color: #344f98;
            font-weight: 700;
          }
          .drop_down_container {
            z-index: 999;
            position: absolute;
            top: 80px;
            left: 0px;
            width: 100%;
            background: #fff;
            .title {
              padding: 0px;
              // width: 200px;
              text-align: justify;
              color: #344f98;
              font-size: 18px;
              font-weight: 700;
              line-height: 26.06px;
            }
            .info {
              color: #808080;
              font-size: 14px;
              line-height: 20.27px;
            }
            img {
              width: 80px;
              height: 80px;
            }
            .en_3D_text {
              width: 156px;
              color: #808080;
              line-height: 17.38px;
              font-weight: 700;
              font-size: 12px;
              text-align: start;
            }
            .zh_text {
              color: #808080;
              line-height: 20.27px;
              font-weight: 700;
              font-size: 14px;
              // text-align: center;
            }
            .en_text {
              color: #808080;
              line-height: 17.38px;
              font-weight: 700;
              font-size: 12px;
              // text-align: center;
            }
            .drop_down_box {
              padding: 25px 148px;
              height: 270px;
              display: flex;
              .drop_item_left {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                border-right: 1px solid #e5e5e5;
                width: 213px;
                .img_item {
                  display: flex;
                  cursor: pointer;
                  flex-direction: column;
                  width: fit-content;
                  align-items: center;
                }
                .text {
                  color: #808080;
                  line-height: 20.27px;
                  font-weight: 700;
                  font-size: 14px;
                }
              }
              .drop_item_center {
                width: 498px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                border-right: 1px solid #e5e5e5;
                padding-left: 34px;
                padding-right: 42px;
                .img_box {
                  display: flex;
                  justify-content: space-between;
                }
                .img_item {
                  cursor: pointer;
                  text-align: center;
                }
              }
              .drop_item_right {
                padding-left: 44px;
                width: 357px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .img_box {
                  text-align: center;
                  display: flex;
                  justify-content: space-between;
                }
                .img_item {
                  cursor: pointer;
                }
                img {
                  width: 138.27px;
                  height: 34.7px;
                }
              }
            }
            .drop_down_box_solutions {
              padding: 25px 155px 0px;
              height: 270px;
              display: flex;
              .solution_drop_item_left {
                margin-bottom: 25px;
                border-right: 1px solid #e5e5e5;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 428px;
                padding-right: 48px;
                .img_box {
                  display: flex;
                  justify-content: space-between;
                  .img_item {
                    cursor: pointer;
                    text-align: center;
                    img {
                      width: 180px;
                      height: 80px;
                    }
                  }
                }
              }
              .zh_solution_drop_item_right_margin_bottom {
                margin-bottom: 25px;
              }
              .en_solution_drop_item_right_margin_bottom {
                margin-bottom: 7.62px;
              }
              .solution_drop_item_right {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding-left: 48px;
                .info {
                  // width: 434px;
                  width: 530px;
                }
                .img_box {
                  display: flex;
                  .img_item {
                    cursor: pointer;
                    margin-right: 20px;
                    text-align: center;
                    width: 180px;
                    img {
                      width: 100%;
                      height: 80px;
                    }
                  }
                  .img_item:last-child {
                    margin: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .right {
      display: flex;
      .language {
        cursor: pointer;
        display: flex;
        align-items: center;
        .lang_text {
          font-size: 18px;
          color: #000;
        }
        .ico {
          margin-right: 3px;
          width: 18px;
          height: 18px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        padding-right: 52px;
      }
      .loginBth {
        height: 27px;
        line-height: 27px;
        width: 120px;
        color: #fff;
        border-radius: 20px;
        text-align: center;
        background: #344f98;
      }
    }
  }
}
</style>
