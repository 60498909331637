export default {
  首页: '首页',
  产品系列: '产品系列',
  解决方案: '解决方案',
  关于我们: '关于我们',
  文档中心: '文档中心',
  OSAI云平台: 'OSAI云平台',
  '实现云端数据同步、设备监控、权限管理，建立高效的数据分析和管理体系。':
    '实现云端数据同步、设备监控、权限管理，建立高效的数据分析和管理体系。',
  '傲视云平台打通产品和服务的连接，建立高效的统计分析和设备管理。':
    '傲视云平台打通产品和服务的连接，建立高效的统计分析和设备管理。',
  '支持各终端、部门和门店等颗粒度的工作和经营情况分析和统计。':
    '支持各终端、部门和门店等颗粒度的工作和经营情况分析和统计。',
  '支持自定义多类型的通知和告警自动推送，支持多种推送方式。':
    '支持自定义多类型的通知和告警自动推送，支持多种推送方式。',
  AI生鲜识别算法: 'AI生鲜识别算法',
  AI零食识别算法: 'AI零食识别算法',
  AI烘焙识别算法: 'AI烘焙识别算法',
  'Tensor Pro': 'Tensor Pro',
  'Tensor Mini': 'Tensor Mini',
  收银解决方案: '收银解决方案',
  条码秤解决方案: '条码秤解决方案',
  原木精确智能检尺解决方案: '原木精确智能检尺解决方案',
  光伏铺装解决方案: '光伏铺装解决方案',
  光伏清洁解决方案: '光伏清洁解决方案',
  联系我们: '联系我们',
  '咨询电话：13346402280': '咨询电话：13346402280',
  '联系邮箱：info@osai.cc': '联系邮箱：info@osai.cc',
  公司简介: '公司简介',
  '珠海傲视创新科技有限公司于2021年3月在高新区注册成立，是一家专注于研发AI视觉识别算法、行业应用及解决方案的人工智能科技公司。':
    '珠海傲视创新科技有限公司于2021年3月在高新区注册成立，是一家专注于研发AI视觉识别算法、行业应用及解决方案的人工智能科技公司。',
  '公司拥有多名资深的神经网络、机器学习和机器视觉专家，毕业于清华大学、北京大学和上海交大计算机系，是公司的核心竞争优势。':
    '公司拥有多名资深的神经网络、机器学习和机器视觉专家，毕业于清华大学、北京大学和上海交大计算机系，是公司的核心竞争优势。',
  '公司致力于打造零编程、自动化、高精度、通用的AI视觉识别算法模型。':
    '公司致力于打造零编程、自动化、高精度、通用的AI视觉识别算法模型。',
  '目前公司专注于新零售、新能源领域的AI人工智能技术的研发和实际应用，帮助客户极大地提升了工作效率。':
    '目前公司专注于新零售、新能源领域的AI人工智能技术的研发和实际应用，帮助客户极大地提升了工作效率。',
  AI收银解决方案: 'AI收银解决方案',
  '支持零代码弹窗插件无缝对接，': '支持零代码弹窗插件无缝对接，',
  '提供多平台、多协议SDK集成方案。': '提供多平台、多协议SDK集成方案。',
  '自动匹配或自动拍摄商品展示图片，并支持手动编辑。':
    '自动匹配或自动拍摄商品展示图片，并支持手动编辑。',
  '支持市面主流的POS收银软件，远程取数、云端自动升级。':
    '支持市面主流的POS收银软件，远程取数、云端自动升级。',
  '自动精准识别，支持拼音首字母或PLU号等商品助记符搜索。':
    '自动精准识别，支持拼音首字母或PLU号等商品助记符搜索。',
  '傲视AI视觉识别核心算法，适应不同光线环境及多种秤盘。':
    '傲视AI视觉识别核心算法，适应不同光线环境及多种秤盘。',
  'Windows推荐配置：': 'Windows推荐配置：',
  'CPU：Intel J1900及以上': 'CPU：Intel J1900及以上',
  '系统：Windows7及以上': '系统：Windows7及以上',
  '内存：4G': '内存：4G',
  '储存：64G': '储存：64G',
  '安卓推荐配置：': '安卓推荐配置：',
  '芯片：SOC RK3288及以上': '芯片：SOC RK3288及以上',
  '系统：安卓7及以上': '系统：安卓7及以上',
  '内存：2G': '内存：2G',
  '储存：16G': '储存：16G',
  'Linux推荐配置：': 'Linux推荐配置：',
  'CPU：x86 / ARM': 'CPU：x86 / ARM',
  '系统：Ubuntu 18 / Debian 11': '系统：Ubuntu 18 / Debian 11',
  '储存：32G': '储存：32G',
  AI智能识别算法: 'AI智能识别算法',
  '自主研发多种类型AI视觉识别核心算法，满足各种行业视觉识别需求。':
    '自主研发多种类型AI视觉识别核心算法，满足各种行业视觉识别需求。',
  '赋能软件服务商，集成AI视觉识别核心算法，快速实现各行业AI视觉识别的智能化应用方案。':
    '赋能软件服务商，集成AI视觉识别核心算法，快速实现各行业AI视觉识别的智能化应用方案。',
  '提供SDK、HTTP API等多种集成方式。': '提供SDK、HTTP API等多种集成方式。',
  '落地边缘计算实际应用，支持实时数据分析，持续迭代优化。':
    '落地边缘计算实际应用，支持实时数据分析，持续迭代优化。',
  行业应用: '行业应用',
  生鲜领域: '生鲜领域',
  '提供Windows系统和安卓系统的完整解决方案，包括：OSAI POS Plugin、OSAI ScaleAI。':
    '提供Windows系统和安卓系统的完整解决方案，包括：OSAI POS Plugin、OSAI ScaleAI。',
  零食行业: '零食行业',
  '针对零食行业自主研发对应的AI视觉识别核心算法，提供完整的软硬件解决方案。':
    '针对零食行业自主研发对应的AI视觉识别核心算法，提供完整的软硬件解决方案。',
  '烘焙行业，团餐行业，': '烘焙行业，团餐行业，',
  '农贸市场，政务执行和治理等。': '农贸市场，政务执行和治理等。',
  AI条码秤解决方案: 'AI条码秤解决方案',
  '算法、应用、硬件、OS及云平台，一站式解决方案。':
    '算法、应用、硬件、OS及云平台，一站式解决方案。',
  算法: 'Algorithm',
  '傲视AI算法拥有毫秒级的快速识别能力，支持多样性商品；核心专利，拥有离线自主识别和学习能力。':
    '傲视AI算法拥有毫秒级的快速识别能力，支持多样性商品；核心专利，拥有离线自主识别和学习能力。',
  应用: '应用',
  '针对专属硬件深度优化应用，确保兼容性，更多自定义功能和竞争优势。':
    '针对专属硬件深度优化应用，确保兼容性，更多自定义功能和竞争优势。',
  OS: 'OS',
  '客制化系统固件，快速满足客户的行业需求。':
    '客制化系统固件，快速满足客户的行业需求。',
  云平台: '云平台',
  '实时监控设备，远程管理终端。': '实时监控设备，远程管理终端。',
  '多维度、多模块、跨平台，持续升级和迭代。':
    '多维度、多模块、跨平台，持续升级和迭代。',
  硬件: '硬件',
  '自主研发的硬件，': '自主研发的硬件，',
  '具有高稳定性和高专业性的优势。': '具有高稳定性和高专业性的优势。',
  产品简介: '产品简介',
  'Tensor Pro是一款能用于室内外全场景的高精度3D相机，可应用于精确测量等场景。搭载自研3D成像算法，室外场景下4米距离内可以达到毫米级深度误差，复杂光照条件下有优良的成像效果。RGB与深度图有4K超高分辨率，RGB图画质清晰，可为后续图像识别算法提供高质量的输入。内置GPU算力，配合SIGAI的3D视觉库及相关开发工具，可快速实现3D视觉算法并集成到相机内部。':
    'Pro是一款能用于室内外全场景的高精度3D相机，可应用于精确测量等场景。搭载自研3D成像算法，室外场景下4米距离内可以达到毫米级深度误差，复杂光照条件下有优良的成像效果。RGB与深度图有4K超高分辨率，RGB图画质清晰，可为后续图像识别算法提供高质量的输入。内置GPU算力，配合SIGAI的3D视觉库及相关开发工具，可快速实现3D视觉算法并集成到相机内部。',
  产品参数: '产品参数',
  '成像效果-木材与工程机械': '成像效果-木材与工程机械',
  '成像效果-光伏板': '成像效果-光伏板',
  'Tensor Mini是一款能用于室内外全场景的高精度3D相机，可用于精确定位与导航等场景。搭载自研3D成像核心算法，2米距离内可以达到毫米级深度误差，近距离可以达到亚毫米级深度误差。内置GPU算力，配合SIGAI的3D视觉库及相关开发工具，可快速实现3D视觉算法并集成到相机内部。':
    'Tensor Mini是一款能用于室内外全场景的高精度3D相机，可用于精确定位与导航等场景。搭载自研3D成像核心算法，2米距离内可以达到毫米级深度误差，近距离可以达到亚毫米级深度误差。内置GPU算力，配合SIGAI的3D视觉库及相关开发工具，可快速实现3D视觉算法并集成到相机内部。',
  '成像效果-塑料球': '成像效果-塑料球',
  '成像效果-陶瓷碗': '成像效果-陶瓷碗',
  '成像效果-地面砖': '成像效果-地面砖',
  原木精确智能: '原木精确智能',
  检尺解决方案: '检尺解决方案',
  '可快速、精确测算出每根原木的直径和体积。':
    '可快速、精确测算出每根原木的直径和体积。',
  方案简介: '方案简介',
  'Tensor Scaler创新性地利用计算机视觉技术实现原木检尺，只需对准木材拍摄一张照片，即可快速、精确测算出每根原木的直径和体积。先进的AI算法确保达到人工平均水准的测量精度，帮用户严格把关。Tensor Scaler是目前最快速的原木测量解决方案。一次测量800根木材30秒可出结果，大幅度削减对人力的需求。':
    'Tensor Scaler创新性地利用计算机视觉技术实现原木检尺，只需对准木材拍摄一张照片，即可快速、精确测算出每根原木的直径和体积。先进的AI算法确保达到人工平均水准的测量精度，帮用户严格把关。Tensor Scaler是目前最快速的原木测量解决方案。一次测量800根木材30秒可出结果，大幅度削减对人力的需求。',
  技术参数: '技术参数',
  使用场景: '使用场景',
  地面堆垛检尺: '地面堆垛检尺',
  夹抱机检尺: '夹抱机检尺',
  卡车木材检尺: '卡车木材检尺',
  集装箱木材检尺: '集装箱木材检尺',
  部署方式: '部署方式',
  固定式部署: '固定式部署',
  '固定部署在闸口的龙门桁架上，机械臂在XYZ3个自由度上运动，对准木材横截面拍照，完成测量。适用于大型用户。':
    '固定部署在闸口的龙门桁架上，机械臂在XYZ3个自由度上运动，对准木材横截面拍照，完成测量。适用于大型用户。',
  便携式部署: '便携式部署',
  '安装在三脚架上，便于移动。适用于作业地点不固定的场景或小型用户':
    '安装在三脚架上，便于移动。适用于作业地点不固定的场景或小型用户',
  测量结果: '测量结果',
  光伏铺装: '光伏铺装',
  photovoltaicLayingSolution: {
    解决方案: '解决方案'
  },
  '大幅度的提高了铺板的作业效率。': '大幅度的提高了铺板的作业效率。',
  '用3D相机引导机械臂对光伏面板进行抓取，将光伏面板精确放到指定的安装位置与姿态。Tensor Eye在2m距离实现了Z值误差<3mm，机械臂综合引导误差<1cm ，扫描成像时间缩短到2s以内，定位精度的提升大幅度的提高了铺板的作业效率。':
    '用3D相机引导机械臂对光伏面板进行抓取，将光伏面板精确放到指定的安装位置与姿态。Tensor Eye在2m距离实现了Z值误差<3mm，机械臂综合引导误差<1cm ，扫描成像时间缩短到2s以内，定位精度的提升大幅度的提高了铺板的作业效率。',
  光伏清洁: '光伏清洁',
  photovoltaicCleaningSolutions: {
    解决方案: '解决方案'
  },
  '用3D相机引导机械臂对光伏清扫机的精确抓取和放置。':
    '用3D相机引导机械臂对光伏清扫机的精确抓取和放置。',
  '需要在室外复杂光照条件下工作，要求2米以上距离拍照时识别、定位误差< 5mm。结构光相机在这种室外场景下不可用，激光雷达、其他3D相机精度无法满足应用要求。SIGAI的3D相机实现了精确的物体定位与姿态估计，在XY方向的定位误差< 5mm@2m，Z方向的定位误差< 3mm@2m，是当前市面上唯一能够满足该应用要求的3D相机。':
    '需要在室外复杂光照条件下工作，要求2米以上距离拍照时识别、定位误差< 5mm。结构光相机在这种室外场景下不可用，激光雷达、其他3D相机精度无法满足应用要求。SIGAI的3D相机实现了精确的物体定位与姿态估计，在XY方向的定位误差< 5mm@2m，Z方向的定位误差< 3mm@2m，是当前市面上唯一能够满足该应用要求的3D相机。',
  行业解决方案: '行业解决方案',
  '基于AI核心算法，深入行业应用，解决用户痛点。':
    '基于AI核心算法，深入行业应用，解决用户痛点。',
  助力传统电子秤快速实现AI打秤应用场景: '助力传统电子秤快速实现AI打秤应用场景',
  OSAI软件: 'OSAI软件',
  传统电子秤: '传统电子秤',
  AI智能识别: 'AI智能识别',
  '对接多种收银系统，快速集成AI视觉识别软硬件系统':
    '对接多种收银系统，快速集成AI视觉识别软硬件系统',
  核心团队: '核心团队',
  云平台服务: '云平台服务',
  '高效的数据分析和设备管理。': '高效的数据分析和设备管理。',
  '商业设备智能化，提升工作效率解决消费者排队等待问题，有效节省商户开支。':
    '商业设备智能化，提升工作效率解决消费者排队等待问题，有效节省商户开支。',
  '3D双目摄像头解决方案': '3D双目摄像头解决方案',
  '室内外全场景的高精度双目立体3D相机。':
    '室内外全场景的高精度双目立体3D相机。',
  '3D双目摄像头': '3D双目摄像头',
  '搭载自研双目立体视觉核心算法，在1.5到30米或0.3到15米距离内可以达到毫米级深度误差。':
    '搭载自研双目立体视觉核心算法，在1.5到30米或0.3到15米距离内可以达到毫米级深度误差。',
  header: {
    首页: '首页',
    产品系列: '产品系列',
    解决方案: '解决方案',
    关于我们: '关于我们',
    文档中心: '文档中心',
    云平台服务: '云平台服务',
    行业解决方案: '行业解决方案',
    '基于AI核心算法，深入行业应用，解决用户痛点。':
      '基于AI核心算法，深入行业应用，解决用户痛点。',
    AI收银解决方案: 'AI收银解决方案',
    '高效的数据分析和设备管理。': '高效的数据分析和设备管理。',
    OSAI云平台: 'OSAI云平台',
    AI智能识别算法: 'AI智能识别算法',
    '3D双目摄像头解决方案': '3D双目摄像头解决方案',
    '搭载自研双目立体视觉核心算法，在1.5到30米或0.3到15米距离内可以达到毫米级深度误差。':
      '搭载自研双目立体视觉核心算法，在1.5到30米或0.3到15米距离内可以达到毫米级深度误差。',
    AI条码秤解决方案: 'AI条码秤解决方案',
    '商业设备智能化，提升工作效率解决消费者排队等待问题，有效节省商户开支。':
      '商业设备智能化，提升工作效率解决消费者排队等待问题，有效节省商户开支。',
    AI生鲜识别算法: 'AI生鲜识别算法',
    AI零食识别算法: 'AI零食识别算法',
    AI烘焙识别算法: 'AI烘焙识别算法',
    '3D双目摄像头': '3D双目摄像头',
    '室内外全场景的高精度双目立体3D相机。':
      '室内外全场景的高精度双目立体3D相机。',
    'Tensor Pro': 'Tensor Pro',
    'Tensor Mini': 'Tensor Mini'
  },
  home: {
    '3D双目摄像头解决方案': '3D双目摄像头解决方案'
  },
  公司专利: '公司专利',
  雷明: '雷明',
  刘彬: '刘彬',
  '地址：': '地址：',
  '电话：': '电话：',
  '邮箱：': '邮箱：',
  '珠海市高新区唐家湾镇湾创路88号706室（港湾1号·创意云端A栋）':
    '珠海市高新区唐家湾镇湾创路88号706室（港湾1号·创意云端A栋）',
  13346402280: '13346402280',
  'info@osai.cc': 'info@osai.cc',
  了解详情: '了解详情',
  '支持零代码弹窗插件无缝对接，提供多平台、多协议SDK集成方案。':
    '支持零代码弹窗插件无缝对接，提供多平台、多协议SDK集成方案。',
  '留言内容（必填）': '留言内容（必填）',
  联系人: '联系人',
  电话: '电话',
  '联系邮箱（必填）': '联系邮箱（必填）',
  提交: ' 提交',
  '赋能行业应用，助力企业降本增效。': '赋能行业应用，助力企业降本增效。',
  公司介绍: '公司介绍',
  '自主研发AI视觉识别算法，整合行业应用的「人工智能科技公司」':
    '自主研发AI视觉识别算法，整合行业应用的「人工智能科技公司」',
  '珠海傲视创新科技有限公司于2021年3月在高新区注册成立，是一家专注于自主研发及销售AI机器视觉识别行业落地应用及解决方案的人工智能创新科技公司。':
    '珠海傲视创新科技有限公司于2021年3月在高新区注册成立，是一家专注于自主研发及销售AI机器视觉识别行业落地应用及解决方案的人工智能创新科技公司。',
  '公司拥有多名资深的神经网络、机器学习和机器视觉专家，均毕业于清华大学、北京大学和上海交大计算机系，是公司的核心竞争优势。':
    '公司拥有多名资深的神经网络、机器学习和机器视觉专家，均毕业于清华大学、北京大学和上海交大计算机系，是公司的核心竞争优势。',
  '公司致力于打造零编程、自动化、高精度、通用的AI机器视觉框架，通过AI智能学习算法赋能各个行业。':
    '公司致力于打造零编程、自动化、高精度、通用的AI机器视觉框架，通过AI智能学习算法赋能各个行业。',
  '目前公司专注于新零售、新能源等领域的AI人工智能技术的研发和实际应用，帮助众多客户极大地提升了经营效率。':
    '目前公司专注于新零售、新能源等领域的AI人工智能技术的研发和实际应用，帮助众多客户极大地提升了经营效率。',
  辨识一瞬间: '辨识一瞬间',
  '傲览方寸之间，视见万物乾坤': '傲览方寸之间，视见万物乾坤',
  登录云平台: '登录云平台'
}
