import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import 'amfe-flexible/index'
import router from '@/router/index'
import '@/styles/index.less' // global css

// 引入国际化插件
import i18n from './lang'
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: '自己注册的ak码'
})

Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')
