export default {
  首页: 'Home',
  产品系列: 'Products',
  解决方案: 'Solutions',
  关于我们: 'About Us',
  文档中心: 'Developer Center',
  OSAI云平台: 'OSAI Cloud',
  '实现云端数据同步、设备监控、权限管理，建立高效的数据分析和管理体系。':
    'Cloud data synchronization, device monitoring, and permission management, making an efficient data analysis and management system.',
  '傲视云平台打通产品和服务的连接，建立高效的统计分析和设备管理。':
    'OSAI Cloud connect devices and services together, making an efficient data analysis and management system.',
  '支持各终端、部门和门店等颗粒度的工作和经营情况分析和统计。':
    'Support the operation analysis and statistics of each terminal, department and store.',
  '支持自定义多类型的通知和告警自动推送，支持多种推送方式。':
    'Supports multiple notification and alarm push.',
  AI生鲜识别算法: 'AI Fresh Recognition Algorithm',
  AI零食识别算法: 'AI Snack Recognition Algorithm',
  AI烘焙识别算法: 'AI Bakery Recognition Algorithm',
  'Tensor Pro': 'Tensor Pro AI Camera',
  'Tensor Mini': 'Tensor Mini AI Camera',
  收银解决方案: 'AI POS Solution',
  条码秤解决方案: 'AI Barcode Scale Solution',
  原木精确智能检尺解决方案: 'Raw Wood Measuring Scale Solution',
  光伏铺装解决方案: 'Solar Panel Paving Solution',
  光伏清洁解决方案: 'Solar Panel Cleaning Solution',
  联系我们: 'Contact Us',
  '咨询电话：13346402280': 'Tel: +86-13346402280',
  '联系邮箱：info@osai.cc': 'Email: info@osai.cc',
  公司简介: 'Company Profile',
  '珠海傲视创新科技有限公司于2021年3月在高新区注册成立，是一家专注于研发AI视觉识别算法、行业应用及解决方案的人工智能科技公司。':
    'OSAI Inc. was established in March 2021, in Zhuhai National Hi-tech Industrial Development Zone. We are focusing in AI visual recognition development. We aim to be a creative provider of industrial applications and solutions.',
  '公司拥有多名资深的神经网络、机器学习和机器视觉专家，毕业于清华大学、北京大学和上海交大计算机系，是公司的核心竞争优势。':
    'We own several senior specialists of neural network, deep learning and visual recognition, who are graduated from Tsinghua University and Shanghai Jiao Tong University. They are the core advantage of the company.',
  '公司致力于打造零编程、自动化、高精度、通用的AI视觉识别算法模型。':
    'We are dedicated to provide less-coding, automation, high precision and universal AI recognition framework, and bring AI recognition algorithm to different industries.',
  '目前公司专注于新零售、新能源领域的AI人工智能技术的研发和实际应用，帮助客户极大地提升了工作效率。':
    'Currently we are working hard in the New Retail industry, providing AI visual recognition solution and applications to the key players and help increasing the daily operating efficiency.',
  AI收银解决方案: 'AI POS Solution',
  '支持零代码弹窗插件无缝对接，':
    'Support zero-coding pop-up plug-in seamless docking.',
  '提供多平台、多协议SDK集成方案。':
    'Provides multi-platform, multi-protocol SDK integration solutions.',
  '自动匹配或自动拍摄商品展示图片，并支持手动编辑。':
    'Automatic matching or shooting of product display pictures',
  '支持市面主流的POS收银软件，远程取数、云端自动升级。':
    'Support the mainstream POS software, remote data access',
  '自动精准识别，支持拼音首字母或PLU号等商品助记符搜索。':
    'Accurate recognition,  support PLU or mnemonic search',
  '傲视AI视觉识别核心算法，适应不同光线环境及多种秤盘。':
    'Adapt to different light environments and a variety of scales',
  'Windows推荐配置：': 'Windows Requirements',
  'CPU：Intel J1900及以上': 'CPU: Intel J1900 and above',
  '系统：Windows7及以上': 'OS: Windows 7 and above',
  '内存：4G': 'RAM: 4G',
  '储存：64G': 'SSD: 64G',
  '安卓推荐配置：': 'Android Requirements',
  '芯片：SOC RK3288及以上': 'SoC: RK3288 and above',
  '系统：安卓7及以上': 'OS: Android 7 and above',
  '内存：2G': 'RAM: 2G',
  '储存：16G': 'ROM: 16G',
  'Linux推荐配置：': 'Linux Requirements',
  'CPU：x86 / ARM': 'CPU: x86 / ARM',
  '系统：Ubuntu 18 / Debian 11': 'OS: Ubuntu 18 / Debian 11',
  '储存：32G': 'Storage: 32G',
  AI智能识别算法: 'AI Visual Recognition Algorithm',
  '自主研发多种类型AI视觉识别核心算法，满足各种行业视觉识别需求。':
    'Independently developed various types of AI visual recognition algorithms to meet the visual recognition needs of various industries.',
  '赋能软件服务商，集成AI视觉识别核心算法，快速实现各行业AI视觉识别的智能化应用方案。':
    'Empower software service providers. Integrating AI visual recognition algorithms, quickly realize AI application solutions for various industries.',
  '提供SDK、HTTP API等多种集成方式。':
    'Providing SDK, HTTP and gRPC API interfaces.',
  '落地边缘计算实际应用，支持实时数据分析，持续迭代优化。':
    'Practical application of edge computing, support real-time data analysis, continuous iterative optimization.',
  行业应用: 'Application in various industries',
  生鲜领域: 'Fresh food',
  '提供Windows系统和安卓系统的完整解决方案，包括：OSAI POS Plugin、OSAI ScaleAI。':
    'Providing total solutions for Windows and Android OS, including OSAI POS Plugin and OSAI ScaleAI.',
  零食行业: 'Snacks shop',
  '针对零食行业自主研发对应的AI视觉识别核心算法，提供完整的软硬件解决方案。':
    'Specially developed for snacks recognition, providing both S/W and H/W solutions.',
  '烘焙行业，团餐行业，': 'Backery, Cafeteria, Wholesale markets,',
  '农贸市场，政务执行和治理等。': 'Government enforcement and governance',
  AI条码秤解决方案: 'AI Barcode Scale Solution',
  '算法、应用、硬件、OS及云平台，一站式解决方案。':
    'Algorithm, application, hardware, OS and cloud platform,one-stop solution.',
  算法: 'Algorithm',
  '傲视AI算法拥有毫秒级的快速识别能力，支持多样性商品；核心专利，拥有离线自主识别和学习能力。':
    'Support detection and recognition in millisecond for various commodities. Offline AI learning and recognition.',
  应用: 'Application',
  '针对专属硬件深度优化应用，确保兼容性，更多自定义功能和竞争优势。':
    'Deeply optimize applications for dedicated hardware to ensure compatibility, more custom features and competitive advantage.',
  OS: 'OS',
  '客制化系统固件，快速满足客户的行业需求。':
    'Customized OS to quickly meet customer industry needs.',
  云平台: 'Cloud Platform',
  '实时监控设备，远程管理终端。':
    'Monitor devices in real time and manage terminals remotely.',
  '多维度、多模块、跨平台，持续升级和迭代。':
    'Multi-dimensional, multi-module, cross-platform, continuous upgrade and iteration.',
  硬件: 'Hardware',
  '自主研发的硬件，': 'Self-developed hardwares,',
  '具有高稳定性和高专业性的优势。':
    'have the advantages of high stability and high professionalism.',
  产品简介: 'Introduction',
  'Tensor Pro是一款能用于室内外全场景的高精度3D相机，可应用于精确测量等场景。搭载自研3D成像算法，室外场景下4米距离内可以达到毫米级深度误差，复杂光照条件下有优良的成像效果。RGB与深度图有4K超高分辨率，RGB图画质清晰，可为后续图像识别算法提供高质量的输入。内置GPU算力，配合SIGAI的3D视觉库及相关开发工具，可快速实现3D视觉算法并集成到相机内部。':
    "Tensor Pro is a high-precision 3D camera that can be used in both indoor and outdoor scenes, and can be used for precise measurements and other scenarios. Equipped with self-developed 3D imaging algorithm, it can achieve millimeter-level depth error within a distance of 4 meters in outdoor scenes, and has excellent imaging effect under complex lighting conditions. RGB and depth maps have 4K ultra-high resolution, and RGB picture quality is clear, which can provide high-quality input for subsequent image recognition algorithms. Built-in GPU computing power, combined with SIGAI's 3D vision library and related development tools, can quickly implement 3D vision algorithms and integrate them into the camera.",
  产品参数: 'Specification',
  '成像效果-木材与工程机械':
    'Imaging effects - Wood and construction machinery',
  '成像效果-光伏板': 'Imaging effect - Solar panel',
  'Tensor Mini是一款能用于室内外全场景的高精度3D相机，可用于精确定位与导航等场景。搭载自研3D成像核心算法，2米距离内可以达到毫米级深度误差，近距离可以达到亚毫米级深度误差。内置GPU算力，配合SIGAI的3D视觉库及相关开发工具，可快速实现3D视觉算法并集成到相机内部。':
    "Tensor Mini is a high-precision 3D camera that can be used in both indoor and outdoor situations, including precise positioning and navigation. Equipped with self-developed 3D imaging core algorithm, millimeter depth error can be achieved within 2 meters distance, and sub-millimeter depth error can be achieved at short distance. Built-in GPU computing power, combined with SIGAI's 3D vision library and related development tools, can quickly implement 3D vision algorithms and integrate them into the camera.",
  '成像效果-塑料球': 'Image effect - Plastic ball',
  '成像效果-陶瓷碗': 'Imaging effect - Ceramic bowl',
  '成像效果-地面砖': 'Imaging effect - Floor tiles',
  原木精确智能: 'Raw Wood',
  检尺解决方案: 'Measuring Scale Solution',
  '可快速、精确测算出每根原木的直径和体积。':
    'The diameter and volume of each log can be measured quickly and accurately.',
  方案简介: 'Inroduction',
  'Tensor Scaler创新性地利用计算机视觉技术实现原木检尺，只需对准木材拍摄一张照片，即可快速、精确测算出每根原木的直径和体积。先进的AI算法确保达到人工平均水准的测量精度，帮用户严格把关。Tensor Scaler是目前最快速的原木测量解决方案。一次测量800根木材30秒可出结果，大幅度削减对人力的需求。':
    "Tensor Scaler's innovative use of computer vision technology enables raw wood to be quickly and accurately measured the diameter and volume of each log simply by pointing a photograph at it. The advanced AI algorithm ensures that the measurement accuracy reaches the artificial average level and helps users strictly check. Tensor Scaler is the fastest log measurement solution available. The results can be obtained in 30 seconds by measuring 800 pieces of wood at a time, greatly reducing the need for manpower.",
  技术参数: 'Specification',
  使用场景: 'Scenarios',
  地面堆垛检尺: 'Surface stacking',
  夹抱机检尺: 'Clamp',
  卡车木材检尺: 'Truck',
  集装箱木材检尺: 'Container',
  部署方式: 'Deployment',
  固定式部署: 'Fixed deployment',
  '固定部署在闸口的龙门桁架上，机械臂在XYZ3个自由度上运动，对准木材横截面拍照，完成测量。适用于大型用户。':
    'Fixed on the gantry truss at the gate, the robot arm moves on XYZ 3 degrees of freedom, aligns with the wood cross section to take pictures and complete the measurement. Suitable for large users.',
  便携式部署: 'Portable deployment',
  '安装在三脚架上，便于移动。适用于作业地点不固定的场景或小型用户':
    'Mounted on a tripod for easy movement. Suitable for scenarios where the work location is not fixed or small users.',
  测量结果: 'Measuring result',
  光伏铺装: 'Solar Panel',
  photovoltaicLayingSolution: {
    解决方案: 'Paving Solution'
  },
  '大幅度的提高了铺板的作业效率。':
    'Greatly improve the work efficiency of paving the solar panel.',
  '用3D相机引导机械臂对光伏面板进行抓取，将光伏面板精确放到指定的安装位置与姿态。Tensor Eye在2m距离实现了Z值误差<3mm，机械臂综合引导误差<1cm ，扫描成像时间缩短到2s以内，定位精度的提升大幅度的提高了铺板的作业效率。':
    'The 3D camera is used to guide the robot arm to grasp the photovoltaic panel, and the photovoltaic panel is precisely placed in the specified installation position and attitude. The Z-value error of the Tensor Eye is less than 3mm at a distance of 2m, and the comprehensive guidance error of the robot arm is less than 1cm. The scanning imaging time is reduced to less than 2s, and the positioning accuracy is improved, which greatly improves the working efficiency of the pavement.',
  光伏清洁: 'Solar Panel',
  photovoltaicCleaningSolutions: {
    解决方案: 'Cleaning Solution'
  },
  '用3D相机引导机械臂对光伏清扫机的精确抓取和放置。':
    'Use the 3D camera to guide the robot arm to accurately grasp and place the solar cleaner.',
  '需要在室外复杂光照条件下工作，要求2米以上距离拍照时识别、定位误差< 5mm。结构光相机在这种室外场景下不可用，激光雷达、其他3D相机精度无法满足应用要求。SIGAI的3D相机实现了精确的物体定位与姿态估计，在XY方向的定位误差< 5mm@2m，Z方向的定位误差< 3mm@2m，是当前市面上唯一能够满足该应用要求的3D相机。':
    "It needs to work under complex outdoor lighting conditions, requiring recognition and positioning error < 5mm when taking photos at a distance of more than 2 meters. Structured light cameras are not available in this outdoor scene, and the accuracy of liDAR and other 3D cameras cannot meet the application requirements. SIGAI's 3D camera achieves accurate object positioning and attitude estimation, with positioning errors < 5mm@2m in the XY direction and < 3mm@2m in the Z direction. Sigai's 3D camera is the only 3D camera on the market that can meet the requirements of this application.",
  行业解决方案: 'Industry Solutions',
  '基于AI核心算法，深入行业应用，解决用户痛点。':
    'Based on the AI core algorithm, in-depth industrial applications, solve user pain points.',
  助力传统电子秤快速实现AI打秤应用场景:
    'Help traditional scales quickly achieve AI weighing application scenarios',
  OSAI软件: 'OSAI ScaleAI',
  传统电子秤: 'Traditional Scale',
  AI智能识别: 'AI Scale',
  '对接多种收银系统，快速集成AI视觉识别软硬件系统':
    'Connect to a variety of POS systems',
  核心团队: 'Core Team',
  云平台服务: 'Cloud Platform',
  '高效的数据分析和设备管理。':
    'Efficient data analysis and equipment management.',
  '商业设备智能化，提升工作效率解决消费者排队等待问题，有效节省商户开支。':
    "Smarter way to improve POS efficiency, reduce customer's queueing time and the cost of consumables.",
  '3D双目摄像头解决方案': '3D Stereo Vision Camera Solutions',
  '室内外全场景的高精度双目立体3D相机。':
    'High-precision binocular stereo 3D cameras for full indoor and outdoor scenes.',
  '3D双目摄像头': '3D Stereo Vision Camera',
  '搭载自研双目立体视觉核心算法，在1.5到30米或0.3到15米距离内可以达到毫米级深度误差。':
    'Equipped with a self-developed binocular stereo vision algorithm, which can achieve millimeter depth error within a distance of 1.5 to 30 meters or 0.3 to 15 meters.',
  header: {
    首页: 'Home',
    产品系列: 'Products',
    解决方案: 'Solutions',
    关于我们: 'About Us',
    文档中心: 'Developer Center',
    云平台服务: 'Cloud Platform',
    行业解决方案: 'Industry Solutions',
    '基于AI核心算法，深入行业应用，解决用户痛点。':
      'Based on the AI core algorithm, in-depth industrial applications, solve user pain points.',
    AI收银解决方案: 'AI POS Solution',
    '高效的数据分析和设备管理。':
      'Efficient data analysis and equipment management.',
    OSAI云平台: 'OSAI Cloud',
    AI智能识别算法: 'AI Visual Recognition Algorithm',
    '3D双目摄像头解决方案': '3D Stereo Vision Camera Solutions',
    '搭载自研双目立体视觉核心算法，在1.5到30米或0.3到15米距离内可以达到毫米级深度误差。':
      'Equipped with a self-developed binocular stereo vision algorithm, which can achieve millimeter depth error within a distance of 1.5 to 30 meters or 0.3 to 15 meters.',
    AI条码秤解决方案: 'AI Barcode Scale Solution',
    '商业设备智能化，提升工作效率解决消费者排队等待问题，有效节省商户开支。':
      "Smarter way to improve POS efficiency, reduce customer's queueing time and the cost of consumables.",
    AI生鲜识别算法: 'AI Fresh Recognition',
    AI零食识别算法: 'AI Snack Recognition',
    AI烘焙识别算法: 'AI Bakery Recognition',
    '3D双目摄像头': '3D Stereo Vision Camera',
    '室内外全场景的高精度双目立体3D相机。':
      'High-precision binocular stereo 3D cameras for full indoor and outdoor scenes.',
    'Tensor Pro': 'Tensor Pro',
    'Tensor Mini': 'Tensor Mini'
  },
  home: {
    '3D双目摄像头解决方案': '3D Stereo Vision Camera'
  },
  公司专利: 'Patents',
  雷明: 'Lei Ming',
  刘彬: 'Liu Bin',
  '地址：': 'Add:',
  '电话：': 'Tel:',
  '邮箱：': 'Email: ',
  '珠海市高新区唐家湾镇湾创路88号706室（港湾1号·创意云端A栋）':
    'Room 706, No. 88, Wangchuang Road, High-tech Zone Zhuhai, Guangdong, China',
  13346402280: '(+86)13346402280',
  'info@osai.cc': 'info@osai.cc',
  了解详情: 'Learn more',
  '支持零代码弹窗插件无缝对接，提供多平台、多协议SDK集成方案。':
    'Support zero-coding pop-up plug-in seamless docking.Provides multi-platform, multi-protocol SDK integration solutions.',
  '留言内容（必填）': 'Message Content (required)',
  联系人: 'Contact',
  电话: 'phone',
  '联系邮箱（必填）': 'Email Address (required)',
  提交: ' Submit',
  '赋能行业应用，助力企业降本增效。':
    'Enabling industrial applications to help enterprises reduce costs and increase efficiency',
  公司介绍: 'OSAI Profile',
  '自主研发AI视觉识别算法，整合行业应用的「人工智能科技公司」':
    'An artificial intelligence technology company,independently develops Al visual recognition algorithms and integrates industry applications',
  '珠海傲视创新科技有限公司于2021年3月在高新区注册成立，是一家专注于自主研发及销售AI机器视觉识别行业落地应用及解决方案的人工智能创新科技公司。':
    'Established in the Zhuhai High-tech Zone in March 2021, OSAl is an innovation driven Al technology company focusing on independent research and development of machine vision recognition algorithms for various industry applications and solutions.',
  '公司拥有多名资深的神经网络、机器学习和机器视觉专家，均毕业于清华大学、北京大学和上海交大计算机系，是公司的核心竞争优势。':
    "Our senior experts of neural network, machine learning and machine vision, who graduated from Tsinghua University, Peking University and Shanghai Jiaotong University computer science Department, are the company's core competitive advantage.",
  '公司致力于打造零编程、自动化、高精度、通用的AI机器视觉框架，通过AI智能学习算法赋能各个行业。':
    'Dedicated to provide less-coding, automation, high precision and universal Al recognition framework, and bring Al recognition algorithm to different industries.',
  '目前公司专注于新零售、新能源等领域的AI人工智能技术的研发和实际应用，帮助众多客户极大地提升了经营效率。':
    'Currently we are working hard in the New Retail industry, providing Al visual recognition solution and applications to the key players and help increasing the daily operating efficiency.',
  辨识一瞬间: ', YES!',
  '傲览方寸之间，视见万物乾坤': 'Visual Recognition',
  登录云平台: 'Login UCP'
}
